import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import * as React from "react";

interface WorkPackagetypesProps {
  options: string[];
  value: string;
  onChange: (type: string) => void;
}

const WorkPackagetypes: React.FunctionComponent<WorkPackagetypesProps> = ({
  options,
  value,
  onChange,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel>Work Package Type</InputLabel>
      <Select
        value={value}
        label="Work Package Type"
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map((option: any) => (
          <MenuItem key={option} value={option} selected={option === value}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default WorkPackagetypes;
