import { Add, Close } from "@mui/icons-material";
import {
  Button,
  Container,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbarContainer } from "@mui/x-data-grid";
import * as React from "react";

import ConnectionForm from "./ConnectionForm";

import { connectionsService } from "../App";
import { IConnection } from "../services/ConnectionService";

const ConnectionsDataGridToolbar: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const openForm = () => setOpen(true);
  const closeForm = () => setOpen(false);
  return (
    <>
      <GridToolbarContainer>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          paddingLeft={1}
        >
          <Typography variant="body1">Connections</Typography>
          <Button startIcon={<Add />} onClick={openForm}>
            Add
          </Button>
        </Stack>
      </GridToolbarContainer>
      <AddConnectionModal open={open} setOpen={setOpen} />
    </>
  );
};

const AddConnectionModal: React.FC<{
  open: boolean;
  setOpen: (value: boolean) => void;
}> = ({ open, setOpen }) => {
  

  return (
    <Modal open={open}>
      <Container
        maxWidth="xs"
        sx={{
          position: "absolute",
          top: "35%",
          left: "50%",
          transform: "translate(-50%, -35%)",
        }}
      >
        <Paper variant="outlined">
          <Stack
            direction="column"
            spacing={2}
            paddingLeft={2}
            paddingRight={2}
            paddingY={1}
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="overline" flex={1}>
                Add a new connection
              </Typography>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Close />
              </IconButton>
            </Stack>

            <ConnectionForm
              onSubmit={(connection: IConnection) => {
                connectionsService.addConnection(connection)
                setOpen(false);
              }}
            />
          </Stack>
        </Paper>
      </Container>
    </Modal>
  );
};

interface ConnectionsDataGridProps {
  
}

const ConnectionsDataGrid: React.FunctionComponent<
  ConnectionsDataGridProps
> = () => {
  const columns: GridColDef[] = [
    {
      field: "instanceName",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "host",
      headerName: "Host",
      flex: 1,
    },
  ];

  const data = connectionsService.getConnections()
  console.log(data)
  return (
    <DataGrid
      autoHeight
      columns={columns}
      rows={data}
      hideFooter={true}
      getRowId={(row) => row.host}
      slots={{ toolbar: ConnectionsDataGridToolbar }}
    ></DataGrid>
  );
};

export default ConnectionsDataGrid;
