import {
  GanttComponent,
  Inject,
  Edit,
  Toolbar,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-gantt";
import * as React from "react";

import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../node_modules/@syncfusion/ej2-layouts/styles/material.css";
import "../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../node_modules/@syncfusion/ej2-grids/styles/material.css";
import "../../node_modules/@syncfusion/ej2-treegrid/styles/material.css";
import "../../node_modules/@syncfusion/ej2-react-gantt/styles/material.css";
import useSWR from "swr";
import { IConnection } from "../services/ConnectionService";
import { IProject } from "../types/Project";

interface GanttProps {
  connection: IConnection;
  project: IProject;
}

const Gantt: React.FunctionComponent<GanttProps> = ({
  connection,
  project,
}) => {
  const { data, error } = useSWR({
    host: connection.host,
    apiKey: connection.apiKey,
    path: `/projects/${project.id}/work_packages`,
  });

  let taskSettings = {
    id: "id",
    name: "subject",
    startDate: "startDate",
    endDate: "dueDate",
    // duration: "Duration",
    // progress: "Progress",
    // child: "subtasks",
    dependency: "dependency",
    parentID: "parentId",
    progress: "percentageDone"
  };
  const toolbarOptions = ['ZoomIn','ZoomOut','ZoomToFit'];
  if (!data) {
    return <>Loading...</>;
  }
  return (
    <GanttComponent
      editSettings={{ allowEditing: true, allowTaskbarEditing: true }}
      sortSettings={{ columns: [{ field: "position" }] }}
      dataSource={data}
      treeColumnIndex={1}
      taskFields={taskSettings}
      splitterSettings={{position: "30%"}}
      toolbar={toolbarOptions}
    >
      <Inject services={[Edit, Toolbar]} />
      <ColumnsDirective>
        <ColumnDirective field="id" width="80" />
        <ColumnDirective
          field="subject"
          headerText="Name"
          width="400"
          allowResizing={true}
        />
        <ColumnDirective field="startDate" />
        <ColumnDirective field="dueDate" />
      </ColumnsDirective>
    </GanttComponent>
  );
};

export default Gantt;
