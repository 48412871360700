import {
  Autocomplete,
  TextField
} from "@mui/material";
import * as React from "react";
import { connectionsService } from "../App";
import { IConnection } from "../services/ConnectionService";

interface ConnectionSelectProps {
  options: IConnection[]
  value: IConnection | null;
  onChange: (connection: IConnection | null) => void;
}

const ConnectionSelect: React.FunctionComponent<ConnectionSelectProps> = ({
  value: connection,
  onChange: setConnection,
}) => {
  const connections = connectionsService.getConnections();

  return (
    <Autocomplete
      id="host"
      value={connection}
      onChange={(_, value) => {
        setConnection(value);
      }}
      selectOnFocus
      options={connections}
      renderInput={(params) => <TextField {...params} label="Connection" />}
      getOptionLabel={(option) => option.instanceName}
    />
  );
};

export default ConnectionSelect;
