import {
  Button,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as React from "react";
import * as yup from "yup";
import OpenProjectService from "../services/OpenProjectService";
import { IConnection } from "../services/ConnectionService";
import { connectionsService } from "../App";

interface ConnectionFormProps {
  onSubmit: (values: IConnection) => void;
}

const ConnectionForm: React.FunctionComponent<ConnectionFormProps> = ({
  onSubmit,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  
  const existingHosts = connectionsService.getConnections()?.map((c) => c.host) ?? [];
  const validationSchema = yup.object({
    host: yup
      .string()
      .url("Enter a valid host url")
      .notOneOf(existingHosts, "Host has already been added.")
      .required("Host is required"),
    apiKey: yup
      .string()
      .matches(
        /[a-z|0-9]{64}/g,
        "API key should be lowercase alphanumeric with 64 characters"
      )
      .required("API key is required"),
  });
  const formik = useFormik({
    initialValues: { name: "", host: "", apiKey: "", instanceName: "" },
    validationSchema,
    onSubmit
  });

  const testConnection = async () => {
    setLoading(true);
    formik.setSubmitting(true)
    try {
      const resp: any = await OpenProjectService.fetcher({
        host: formik.values.host,
        apiKey: formik.values.apiKey,
        path: "/root",
      });
      if (resp.message || resp.error) {
        setError(JSON.stringify(resp))
      } else {
        formik.setFieldValue("instanceName", resp.instanceName);
      }
    } catch (err) {
      setError(JSON.stringify(err))
    }
    formik.setSubmitting(false)
    setLoading(false);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Stack padding={2} spacing={2}>
          <TextField
            fullWidth
            id="host"
            name="host"
            label="Host"
            value={formik.values.host}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.host && Boolean(formik.errors.host)}
            helperText={formik.touched.host && formik.errors.host}
          />
          <TextField
            fullWidth
            id="apiKey"
            name="apiKey"
            label="API Key"
            type="password"
            value={formik.values.apiKey}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.apiKey && Boolean(formik.errors.apiKey)}
            helperText={formik.touched.apiKey && formik.errors.apiKey}
          />

          {formik.values.instanceName && (
            <TextField
              fullWidth
              variant="filled"
              id="instanceName"
              name="instanceName"
              label="Instance Name"
              value={formik.values.instanceName}
              disabled
            />
          )}

          {loading && <LinearProgress />}
          {error && <Typography>{error}</Typography>}

          <Stack direction="row" justifyContent="space-around" paddingTop={2}>
            <Button
              variant="outlined"
              onClick={testConnection}
              disabled={
                !(
                  formik.isValid &&
                  formik.touched.host &&
                  formik.touched.apiKey
                ) || loading
              }
              color={formik.values.instanceName ? "success" : "primary"}
            >
              Test
            </Button>
            <Button
              disabled={formik.values.instanceName === ""}
              variant="contained"
              type="submit"
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </form>
    </div>
  );
};

export default ConnectionForm;
