import React from "react";
import ReactDOM from "react-dom/client";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import { SWRConfig } from "swr/_internal";
import App from "./App";
import {
  OpenProjectContextFromProvider,
  OpenProjectContextToProvider,
} from "./context/OpenProjectContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import OpenProjectService from "./services/OpenProjectService";
import { registerLicense } from '@syncfusion/ej2-base'


registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWRCfEx0Rnxbf1x0ZFRGalxWTndfUiweQnxTdEFjWH1acXdVTmNZVkRxWQ==');
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <OpenProjectContextFromProvider>
      <OpenProjectContextToProvider>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            shouldRetryOnError: false,
            fetcher: OpenProjectService.fetcher,
          }}
        >
          <CssBaseline />
          <App />
        </SWRConfig>
      </OpenProjectContextToProvider>
    </OpenProjectContextFromProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
