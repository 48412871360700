import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  OpenProjectFromContext,
  OpenProjectToContext,
} from "./context/OpenProjectContext";
import BulkAddPage from "./pages/BulkAddPage";
import ConnectionsPage from "./pages/ConnectionsPage";
import HomePage from "./pages/HomePage";
import ConnectionService from "./services/ConnectionService";
import SchedulePage from "./pages/SchedulePage";

export const connectionsService = new ConnectionService();

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },

  {
    path: "/connections",
    element: <ConnectionsPage />,
  },
  {
    path: "/bulkAdd",
    element: <BulkAddPage />,
  },
  {
    path: "/schedule",
    element: <SchedulePage />,
  },
]);

function App() {
  const [panel, setPanel] = React.useState(0);
  const { project: projectFrom } = React.useContext(OpenProjectFromContext);
  const { project: projectTo } = React.useContext(OpenProjectToContext);

  return (
    
      
        
        <RouterProvider router={router} />

        // {/* <Grid container spacing={2} paddingBottom={2}>
        //     <Grid item xs={6}>
        //       <Typography>Copy From</Typography>
        //     </Grid>
        //     <Grid item xs={6}>
        //       <Typography>Copy To</Typography>
        //     </Grid>
        //     <Grid item xs={6}>
        //       <ProjectForm context={OpenProjectFromContext} />
        //       <ConnectionForm />
        //     </Grid>
        //     <Grid item xs={6}>
        //       <ProjectForm context={OpenProjectToContext} />
        //     </Grid>
        //   </Grid>

        //   {projectFrom && projectTo && (
        //     <>
        //       <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        //         <Tabs value={panel} onChange={(_, value) => setPanel(value)}>
        //           <Tab label="Copy Children" />
        //         </Tabs>
        //       </Box>
        //       <Box
        //         role="tabpanel"
        //         hidden={panel !== 0}
        //         id={`simple-tabpanel-copy-children`}
        //         paddingTop={1}
        //       >
        //         <CopyChildrenTasksForm />
        //       </Box>
        //     </>
        //   )} */}
  
  );
}

export default App;
