import {
  Box,
  Card,
  CardContent,
  List,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import NavMenuItem from "../components/NavMenuItem";

interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = () => {
  return (
    <PageContainer>
      <Box sx={{ maxWidth: 320 }}>
        <Card variant="outlined">
          <CardContent>
            <Typography component="h2" variant="h6">
              Tools
            </Typography>
            <List component="nav">
              <NavMenuItem to="/bulkAdd">Bulk Add</NavMenuItem>
              <NavMenuItem to="/schedule">Schedule</NavMenuItem>
            </List>
          </CardContent>
        </Card>
      </Box>
    </PageContainer>
  );
};

export default HomePage;
