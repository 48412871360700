import { Close, Refresh } from "@mui/icons-material";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as React from "react";
import useSWR from "swr";
import { connectionsService } from "../App";
import ConnectionAutocomplete from "../components/ConnectionAutocomplete";
import LoadingModal from "../components/LoadingModal";
import PageContainer from "../components/PageContainer";
import ProjectSearch from "../components/ProjectSearch";
import WorkPackageSearch from "../components/WorkPackageSearch";
import WorkPackageTypesSelect from "../components/WorkPackageTypesSelect";
import { IConnection } from "../services/ConnectionService";
import OpenProjectService from "../services/OpenProjectService";
import { IProject } from "../types/Project";
import IWorkPackage from "../types/WorkPackage";

interface BulkAddPageProps {}

const BulkAddPage: React.FC<BulkAddPageProps> = () => {
  const [connection, setConnection] = React.useState<IConnection | null>(null);
  const { data: connections } = useSWR("/connections", () =>
    connectionsService.getConnections()
  );

  const [project, setProject] = React.useState<IProject | null>(null);
  const { data: projects } = useSWR(
    connection && {
      host: connection.host,
      apiKey: connection.apiKey,
      path: "/projects",
    }
  );

  const [workPackage, setWorkPackage] = React.useState<IWorkPackage | null>(
    null
  );
  const { data: workPackages, mutate: reloadWorkPackages } = useSWR(
    connection &&
      project && {
        host: connection.host,
        apiKey: connection.apiKey,
        path: `/projects/${project.id}/work_packages`,
      }
  );

  const [workPackageType, setWorkPackageType] = React.useState<string>("Task");
  const { data: workPackageTypes } = useSWR(
    connection &&
      project && {
        host: connection.host,
        apiKey: connection.apiKey,
        path: `/projects/${project.id}/types`,
      }
  );

  const changeConnection = (connection: IConnection | null) => {
    setConnection(connection);
    setProject(null);
    setWorkPackage(null);
    setWorkPackageType("Task");
  };


  const changeProject = (project: IProject | null) => {
    setProject(project);
    setWorkPackage(null);
    setWorkPackageType("Task");
  };

  

  const [result, setResult] = React.useState("");

  const formik = useFormik({
    initialValues: { data: "" },
    onSubmit: async () => {
      if (connection !== null && project !== null) {
        const { host, apiKey } = connection;
        const lines = getLines();
        for (let line of lines) {
          await OpenProjectService.fetcher({
            host,
            apiKey,
            path: `/projects/${project.id}/work_packages`,
            method: "POST",
            body: {
              subject: line,
              type: workPackageType,
              parentId: workPackage?.id ?? "",
            },
          });
        }
        reloadWorkPackages();
        setResult(`Work packages added`);
      }
    },
  });

  const getLines = () => {
    let lines: string[] = [];
    if (formik.values.data) {
      lines = formik.values.data.split("\n");
      lines = lines.filter((line) => line);
    }
    return lines;
  };
  const lineCount = getLines().length;

  return (
    <PageContainer title="Bulk Add Work Packages">
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {connections && (
              <Grid item xs={12}>
                <ConnectionAutocomplete
                  options={connections}
                  value={connection}
                  onChange={changeConnection}
                />
              </Grid>
            )}
            {projects && (
              <Grid item xs={12}>
                <ProjectSearch
                  options={projects}
                  value={project}
                  onChange={changeProject}
                />
              </Grid>
            )}
            {workPackages && (
              <>
                <Grid item xs={12} sm={8}>
                  <Stack direction="row" alignItems={"center"}>
                    <Box sx={{ flexGrow: 1 }}>
                      <WorkPackageSearch
                        options={workPackages}
                        value={workPackage}
                        onChange={setWorkPackage}
                        label="Add tasks as children under this parent"
                      />
                    </Box>
                    <IconButton onClick={reloadWorkPackages}>
                      <Refresh />
                    </IconButton>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {workPackageTypes && (
                    <WorkPackageTypesSelect
                      options={workPackageTypes.map((wpt: any) => wpt.name)}
                      value={workPackageType}
                      onChange={setWorkPackageType}
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <FormLabel>
                    Add new work packages on a seperate lines
                  </FormLabel>
                  <TextField
                    minRows={8}
                    maxRows={16}
                    multiline
                    style={{ width: "100%" }}
                    name="data"
                    value={formik.values.data}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack alignItems="center">
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={lineCount === 0 || formik.isSubmitting}
                    >
                      Add {lineCount ? lineCount : ""} Work Package
                      {lineCount > 1 ? "s" : ""}
                    </Button>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </div>
      <LoadingModal show={formik.isSubmitting} />
      <Snackbar
        open={result !== ""}
        onClose={() => setResult("")}
        autoHideDuration={6000}
        message={result}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setResult("")}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </PageContainer>
  );
};

export default BulkAddPage;
