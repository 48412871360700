import { IProject } from "../types/Project";
import { IConnection } from "./ConnectionService";

export default class OpenProjectService {

  constructor(private connection?: IConnection) {

  }

  public static async fetcher(req: IOpenProjectRequest) {

    if (!req.host) {
      throw `Must include host with request`;
    }
    if (!req.apiKey) {
      throw "Must include apikey with request";
    }
    if (!req.path) {
      throw "Must include path with request";
    }

    try {
      
      const resp = await fetch(req.path, {
        method: req.method ?? "GET",
        body: JSON.stringify(req.body),
        headers: {
          "Content-type": "application/json",
          "x-openproject-host": req.host,
          "x-openproject-apikey": req.apiKey,
        },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return await resp.json();
      }
    } catch (err) {
      return {error: err}
    }
  }

  public async request(path: string, method = "GET", body?: any) {

    if (!this.connection?.host) {
      throw `Must include host with request`;
    }
    if (!this.connection?.apiKey) {
      throw "Must include apikey with request";
    }
    if (!path) {
      throw "Must include path with request";
    }

    try {
      
      const resp = await fetch(path, {
        method: method ?? "GET",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json",
          "x-openproject-host": this.connection.host,
          "x-openproject-apikey": this.connection.apiKey,
        },
      });
      if (resp.status < 400) {
        return await resp.json();
      } else {
        throw await resp.json();
      }
    } catch (err) {
      return {error: err}
    }
  }

  public async getWorkPackageTypes(project?: IProject) {
    if (!project) {
      throw `Project is required`
    }
    let types: IWorkPackageType[]
    const resp = await this.request(`/projects/${project.id}/types`)
    types = resp
    return types
  }

}

export interface IOpenProjectRequest {
  host: string | null;
  apiKey: string | null;
  path: string | null;
  method?: "GET" | "POST";
  body?: any;
}

export interface IWorkPackageType {
  name: string
}