import { ChevronLeft, Home, Menu } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Typography
} from "@mui/material";
import * as React from "react";
import NavMenuItem from "./NavMenuItem";

interface PageContainerProps extends React.PropsWithChildren {
  title?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"
}

const PageContainer: React.FunctionComponent<PageContainerProps> = ({
  children,
  title,
  maxWidth
}) => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <>
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
          mb: 4,
        }}
      >
        <Toolbar sx={{ pr: "24px" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <Menu />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Open Project Tools
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer open={open}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "240px",
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeft />
          </IconButton>
        </Toolbar>
        <NavMenuItem to="/">
          <Home sx={{ mr: 2 }} /> Home
        </NavMenuItem>
        <Divider />
        <List component="nav">
          <NavMenuItem to="/bulkAdd">Bulk Add</NavMenuItem>
          <NavMenuItem to="/schedule">Schedule</NavMenuItem>
          <Divider sx={{ my: 1 }} />
          <NavMenuItem to="/connections">Settings</NavMenuItem>
        </List>
      </Drawer>

      <Container component="main" maxWidth={maxWidth ?? "md"} sx={{ mb: 8 }}>
        <Box sx={{ mb: 2 }}>
          <Typography component="h2" variant="h6">
            {title}
          </Typography>
        </Box>
        {children}
      </Container>
    </>
  );
};

export default PageContainer;
