import { MenuItem } from '@mui/material';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

interface NavMenuItemProps extends React.PropsWithChildren{
  to: string
}
 
const NavMenuItem: React.FunctionComponent<NavMenuItemProps> = ({to, children}) => {
  return ( <NavLink to={to}>
    {(props) => 
    <MenuItem selected={props.isActive}>{children}</MenuItem>
    }
  </NavLink> );
}
 
export default NavMenuItem;