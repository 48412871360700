import * as React from "react";
import PageContainer from "../components/PageContainer";
import Gantt from "../components/Gantt";
import ProjectSelectionForm from "../components/ProjectSelectionForm";
import { IConnection } from "../services/ConnectionService";
import { IProject } from "../types/Project";
import { Box } from "@mui/material";

interface SchedulePageProps {}

const SchedulePage: React.FC<SchedulePageProps> = () => {
  const [connection, setConnection] = React.useState<IConnection | null>(null);
  const [project, setProject] = React.useState<IProject | null>(null);
  return (
    <PageContainer title="Schedule" maxWidth="xl">
      <Box sx={{ mb: 2 }}>
        <ProjectSelectionForm
          changeConnection={setConnection}
          changeProject={setProject}
        />
      </Box>

      {connection && project && <Gantt connection={connection} project={project} />}
    </PageContainer>
  );
};

export default SchedulePage;
