import { Grid } from "@mui/material";
import * as React from "react";
import ConnectionsDataGrid from "../components/ConnectionsDataGrid";

import PageContainer from "../components/PageContainer";

interface ConnectionsPageProps {}

const ConnectionsPage: React.FunctionComponent<ConnectionsPageProps> = () => {
  return (
    
      <PageContainer title="Settings">
        <ConnectionsDataGrid />
      </PageContainer>
    
  );
};

export default ConnectionsPage;
