import * as React from "react";
import useSWR from "swr";
import { connectionsService } from "../App";
import { IConnection } from "../services/ConnectionService";
import { IProject } from "../types/Project";
import { Grid } from "@mui/material";
import ConnectionAutocomplete from "./ConnectionAutocomplete";
import ProjectSearch from "./ProjectSearch";
interface ProjectSelectionFormProps {
  changeConnection?: (connection: IConnection | null) => void
  changeProject?: (connection: IProject | null) => void
}

const ProjectSelectionForm: React.FunctionComponent<
  ProjectSelectionFormProps
> = ({
  changeConnection, changeProject
}) => {
  const [connection, setConnection] = React.useState<IConnection | null>(null);
  const { data: connections } = useSWR("/connections", () =>
    connectionsService.getConnections()
  );

  const [project, setProject] = React.useState<IProject | null>(null);
  const { data: projects } = useSWR(
    connection && {
      host: connection.host,
      apiKey: connection.apiKey,
      path: "/projects",
    }
  );
  return (
    <Grid container spacing={2}>
      {connections && (
        <Grid item xs={12}>
          <ConnectionAutocomplete
            options={connections}
            value={connection}
            onChange={(connection) => {
              setConnection(connection)
              if (changeConnection) {
                changeConnection(connection)
              }
              setProject(null)
              if (changeProject) {
                changeProject(null)
              }
            }}
          />
        </Grid>
      )}
      {projects && (
        <Grid item xs={12}>
          <ProjectSearch
            options={projects}
            value={project}
            onChange={(project) => {
              setProject(project)
              if (changeProject) {
                changeProject(project)
              }
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectSelectionForm;
