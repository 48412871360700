

export default class ConnectionService {
  private connections: IConnection[] = []
  constructor() {
    const savedConnections = window.localStorage.getItem("openproject-tools-connections")
    if (savedConnections) {
      this.connections = JSON.parse(savedConnections)
    }
  }

  public getConnections() {
    return this.connections
  }
  public addConnection(connection: IConnection) {
    this.connections.push(connection)
    this.connections.sort((a, b) => a.instanceName > b.instanceName ? 1 : -1)
  }
  public removeConnection(connection: IConnection) {
    const idx = this.connections.indexOf(connection)
    this.connections.splice(idx, 1)
  }

}


export interface IConnection {
  instanceName: string;
  host: string;
  apiKey: string;
}


