import {
  Box,
  CircularProgress,
  Container,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";

interface LoadingModalProps {
  show: boolean;
}

const LoadingModal: React.FunctionComponent<LoadingModalProps> = ({ show }) => {
  return (
    <Modal open={show}>
      <Container
        maxWidth="xs"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Stack height="100vh" alignItems="center" justifyContent="center">
          <Paper>
            <Stack width="300px" alignItems="center" paddingY={6}>
              <CircularProgress />
              <Typography>Loading...</Typography>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </Modal>
  );
};

export default LoadingModal;
