import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import * as React from "react";
import useSWR from "swr";
import { IConnection } from "../services/ConnectionService";
import { IProject } from "../types/Project";

interface ProjectSearchProps {
  options: IProject[]
  value: IProject | null
  onChange: (project: IProject | null) => void
  filter?: (project: IProject) => boolean;
}

const ProjectSearch: React.FunctionComponent<ProjectSearchProps> = ({
  options,
  value: project,
  onChange: setProject,
  filter
}) => {
  

  let searchResults: Array<IProject | null> = [];
  if (Array.isArray(options)) {
    if (filter) {
      searchResults = [null, ...options.filter(filter)]
    } else {
      searchResults = [null, ...options];
    }
  }
  
  return (
    <>
      <Autocomplete
        clearOnBlur
        disabled={!options}
        id="project-autocomplete"
        value={project}
        onChange={(_, value: IProject | null) => {
          setProject(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search for a project"
            label="Project"
          />
        )}
        options={searchResults}
        getOptionLabel={(result: any) => {
          return result ? `${result.id} - ${result.name}` : "";
        }}
      />
      {!options && <LinearProgress />}
    </>
  );
};

export default ProjectSearch;
