import {
  Autocomplete,
  Box,
  Chip,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import useSWR from "swr";
import { IOpenProjectContext } from "../context/OpenProjectContext";
import IWorkPackage from "../types/WorkPackage";
import { IConnection } from "../services/ConnectionService";
import { IProject } from "../types/Project";

interface WorkPackageSearchProps {
  options: IWorkPackage[];
  value: IWorkPackage | null;
  onChange: (workPackage: IWorkPackage | null) => void
  filter?: (workPackage: IWorkPackage) => boolean;
  label?: string
}

const WorkPackageSearch: React.FunctionComponent<WorkPackageSearchProps> = ({
  options,
  value,
  onChange,
  filter,
  label
}) => {
  
  let searchResults: Array<IWorkPackage | null> = [];
  if (Array.isArray(options)) {
    if (filter) {
      searchResults = [null, ...options.filter(filter)]
    } else {
      searchResults = [null, ...options];
    }
  }
  return (
    <>
      <Autocomplete
        clearOnBlur
        disabled={!options}
        id="workpackage-autocomplete"
        value={value}
        onChange={(_: any, value: IWorkPackage | null) => {
          onChange(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search for a work package"
            label={label ?? "Work Package"}
          />
        )}
        options={searchResults}
        getOptionLabel={(result: any) => {
          return result ? `${result.id} - ${result.subject}` : "";
        }}
        renderOption={(props, option) => {
          if (option == null) {
            return <></>;
          }
          return (
            <li {...props} key={option.id}>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                width="100%"
              >
                <Typography flex="1">
                  {option.id} - {option.subject}
                </Typography>
                <Chip label={option.type} />
              </Stack>
            </li>
          );
        }}
      />
      {!options && <LinearProgress />}
      
    </>
  );
};

export default WorkPackageSearch;
