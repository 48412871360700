import React, { ReactNode } from "react";
import IWorkPackage from "../types/WorkPackage";
import { IProject } from "../types/Project";

interface OpenProjectContextProviderProps {
  children: ReactNode;
}

export interface IOpenProjectContext {
  host: string | null;
  setHost: (value: string | null) => void;
  apiKey: string | null;
  setApiKey: (value: string | null) => void;
  project: IProject | null;
  setProject: (value: IProject | null) => void;
  workPackage: IWorkPackage | null;
  setWorkPackage: (value: IWorkPackage | null) => void;
}

const defaultValue = {
  host: "http://johnhagler.tplinkdns.com:8080",
  setHost: () => {},
  apiKey: "9df20b15e525d272610afca8eb8ec60cbe3d30a7e790b219f4868526715a4f10",
  setApiKey: () => {},
  project: null,
  setProject: () => {},
  workPackage: null,
  setWorkPackage: () => {},
};

export const OpenProjectToContext =
  React.createContext<IOpenProjectContext>(defaultValue);

export const OpenProjectContextToProvider: React.FC<
  OpenProjectContextProviderProps
> = ({ children }) => {
  const [host, setHost] = React.useState<string | null>(defaultValue.host);
  const [apiKey, setApiKey] = React.useState<string | null>(
    defaultValue.apiKey
  );
  const [project, setProject] = React.useState<IProject | null>(
    defaultValue.project
  );
  const [workPackage, setWorkPackage] = React.useState<IWorkPackage | null>(
    defaultValue.workPackage
  );

  return (
    <OpenProjectToContext.Provider
      value={{
        host,
        setHost: (host: string | null) => {
          setHost(host);
          setApiKey(null);
          setProject(null);
          setWorkPackage(null);
        },
        apiKey,
        setApiKey: (apiKey: string | null) => {
          setApiKey(apiKey);
          setProject(null);
          setWorkPackage(null);
        },
        project,
        setProject: (project: IProject | null) => {
          setProject(project);
          setWorkPackage(null);
        },
        workPackage,
        setWorkPackage,
      }}
    >
      {children}
    </OpenProjectToContext.Provider>
  );
};

export const OpenProjectFromContext =
  React.createContext<IOpenProjectContext>(defaultValue);

export const OpenProjectContextFromProvider: React.FC<
  OpenProjectContextProviderProps
> = ({ children }) => {
  const [host, setHost] = React.useState<string | null>(defaultValue.host);
  const [apiKey, setApiKey] = React.useState<string | null>(
    defaultValue.apiKey
  );
  const [project, setProject] = React.useState<IProject | null>(
    defaultValue.project
  );
  const [workPackage, setWorkPackage] = React.useState<IWorkPackage | null>(
    defaultValue.workPackage
  );

  return (
    <OpenProjectFromContext.Provider
      value={{
        host,
        setHost: (host: string | null) => {
          setHost(host);
          setApiKey(null);
          setProject(null);
          setWorkPackage(null);
        },
        apiKey,
        setApiKey: (apiKey: string | null) => {
          setApiKey(apiKey);
          setProject(null);
          setWorkPackage(null);
        },
        project,
        setProject: (project: IProject | null) => {
          setProject(project);
          setWorkPackage(null);
        },
        workPackage,
        setWorkPackage,
      }}
    >
      {children}
    </OpenProjectFromContext.Provider>
  );
};
